import sep2022 from "./assets/boardminutes/Minutes-Board-of-Directors-Sept-29-2022-Executive Summary.pdf";

import july2022 from "./assets/boardminutes/Minutes-Board-of-Directors-July-26-2022-Executive-Summary.pdf";

import march2022 from "./assets/boardminutes/Minutes-Board-of-Directors-March-31-2022-Executive-Summary.pdf";

import jan2022 from "./assets/boardminutes/Minutes-Board-of-Directors-January-27-2022-Executive-Summary.pdf";

import nov2021 from "./assets/boardminutes/Minutes-Board-of-Directors-Sept-15-2021-Executive-Summary.pdf";

import sept2021 from "./assets/boardminutes/Minutes-Board-of-Directors-Sept-15-2021-Executive-Summary.pdf";

import july2021 from "./assets/boardminutes/Minutes-Board-of-Directors-July-22-2021-Executive-Summary.pdf";

import May2021 from "./assets/boardminutes/Minutes-Board-of-Directors-May-27-2021-Executive-Summary.pdf";

import nov2022 from "./assets/boardminutes/Minutes-Board-of-Directors-Nov-24-2022-Executive Summar.pdf";

import jan2023 from "./assets/boardminutes/Minutes Board of Directors Jan 26 2023 - Executive Summary.pdf";

import mar2023 from "./assets/boardminutes/Minutes Board of Directors Mar 23 2023.pdf";

import may2023 from "./assets/boardminutes/EACC - Minutes Board of Directors May 17 2023.pdf";

import jul2023 from "./assets/boardminutes/EACC - Minutes Board of Directors July 27 2023.pdf";

import sep2023 from "./assets/boardminutes/EACC - Minutes Board of Directors September 28, 2023.pdf";

import nov2023 from "./assets/boardminutes/EACC - Minutes Board of Directors November 23, 2023.pdf";

import feb2024 from "./assets/boardminutes/EACC - Minutes Board of Directors February 6, 2024.pdf";

import mar2024 from "./assets/boardminutes/EACC - Minutes Board of Directors March 28, 2024.pdf";

import may2024 from "./assets/boardminutes/EACC - Minutes Board of Directors May 16, 2024.pdf";

export default [
  {
    date: "May 16, 2024",
    pdf: may2024,
  },
  {
    date: "March 28, 2024",
    pdf: mar2024,
  },
  {
    date: "February 6, 2024",
    pdf: feb2024,
  },
  {
    date: "November 23, 2023",
    pdf: nov2023,
  },
  {
    date: "September 28, 2023",
    pdf: sep2023,
  },
  {
    date: "July 27, 2023",
    pdf: jul2023,
  },
  {
    date: "May 17, 2023",
    pdf: may2023,
  },
  {
    date: "March 23, 2023",
    pdf: mar2023,
  },
  {
    date: "January 26, 2023",
    pdf: jan2023,
  },
  {
    date: "November 24, 2022",
    pdf: nov2022,
  },
  {
    date: "September 29, 2022",
    pdf: sep2022,
  },
  {
    date: "July 26, 2022",
    pdf: july2022,
  },
  {
    date: "March 31, 2022",
    pdf: march2022,
  },
  {
    date: "January 27, 2022",
    pdf: jan2022,
  },
  {
    date: "November 25, 2021",
    pdf: nov2021,
  },
  ,
  {
    date: "September 15, 2021",
    pdf: sept2021,
  },
  ,
  {
    date: "July 22, 2021",
    pdf: july2021,
  },
  ,
  {
    date: "May 27, 2021",
    pdf: May2021,
  },
];
